import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css'; // Global styles
import ModelViewer from './components/modelViewerComponent';

const FrontPage = () => {
  const navigate = useNavigate();
  const [showMotionModal, setShowMotionModal] = useState(false);
  const [motionPermissionGranted, setMotionPermissionGranted] = useState(false);

  const checkMotionPermission = useCallback(async () => {
    if (typeof DeviceMotionEvent !== 'undefined' && typeof DeviceMotionEvent.requestPermission === 'function') {
      try {
        const permission = await DeviceMotionEvent.requestPermission();
        setMotionPermissionGranted(permission === 'granted');
        setShowMotionModal(permission !== 'granted');
      } catch (error) {
        console.error('Error requesting motion permission:', error);
        setShowMotionModal(true);
      }
    } else {
      setMotionPermissionGranted(true); // Non-iOS devices or browsers without permission handling
    }
  }, []);

  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile && !motionPermissionGranted) {
      checkMotionPermission();
    }
  }, [motionPermissionGranted, checkMotionPermission]);

  const handleMotionPermission = useCallback(async () => {
    await checkMotionPermission();
  }, [checkMotionPermission]);

  const handleAllFreqsClick = useCallback(() => {
    navigate('/gallery');
  }, [navigate]);

  return (
    <div className="landing-container">
      {/* Logo positioned at the top left */}
      <div className="logo-container frontpage-logo">
        <img src="/assets/LOGO.png" alt="Logo" className="logo-image" />
      </div>

      {/* Mouth image in the center */}
      <div className="mouth-container">
        <ModelViewer />
      </div>

      {/* All Freqs button (ENTER) */}
      <div className="gallery-button" onClick={handleAllFreqsClick}></div>

      {/* Motion Control Modal */}
      {showMotionModal && !motionPermissionGranted && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Enable Motion Controls</h2>
            <p>For a better experience, please enable motion controls on your device.</p>
            <button onClick={handleMotionPermission}>Enable Motion</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FrontPage;
