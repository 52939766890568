// src/App.js - powered by Procrastinate Pixels - www.procrastinatepixels.com
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css'; // Global styles
import FrontPage from './FrontPage';
import ResultPage from './ResultPage';
import Gallery from './Gallery'; // Import the new Gallery page

const App = () => {
  const [isLandscape, setIsLandscape] = useState(false);

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsLandscape(window.orientation === 90 || window.orientation === -90);
    };

    window.addEventListener('orientationchange', handleOrientationChange);
    handleOrientationChange(); // Check initial orientation

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  return (
    <>
    <div className="app-wrapper">
      <div className="background-container">
        <img 
          srcSet="/assets/concrete-small.jpg 1x, /assets/concrete-medium.jpg 2x, /assets/concrete-large.jpg 3x"
          src="/assets/concrete-medium.jpg"
          alt="Concrete background"
          className="background-image"
        />
      </div>
      <Router>
        <Routes>
          <Route path="/" element={<FrontPage />} />
          <Route path="/result/:id" element={<ResultPage />} />
          <Route path="/gallery" element={<Gallery />} /> {/* Add the gallery route */}
        </Routes>
      </Router>
      {isLandscape && (
        <div className="orientation-message">
          Please rotate your device to portrait mode for the best experience.
        </div>
      )}
    </div>
    </>
  );
};

export default App;