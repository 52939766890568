// ResultPage.js - powered by Procrastinate Pixels - www.procrastinatepixels.com
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './App.css'; // Import your CSS

const ResultPage = () => {
  const { id } = useParams();
  const [metaData, setMetaData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const response = await fetch(`https://api.ordinalsbot.com/get-collection-json?id=crypto-freqs`);
        const data = await response.json();

        const foundItem = data.find(item => item.meta && item.meta.name && item.meta.name.endsWith(`#${id}`));
        if (foundItem) {
          setMetaData(foundItem);
        } else {
          setError("No metadata found for this ID.");
        }
      } catch (err) {
        setError("Failed to fetch metadata.");
      } finally {
        setLoading(false);
      }
    };

    fetchMetaData();
  }, [id]);

  const handleImageClick = () => {
    if (metaData && metaData.id) {
      const url = `https://gamma.io/inscription/${metaData.id}`;
      window.open(url, '_blank');
    }
  };

  // Navigate back when the logo is clicked
  const handleLogoClick = () => {
    navigate('/gallery'); // This navigates back to the gallery
  };

  // Function to extract only the ID part from the metadata name
  const extractId = (name) => {
    return name.replace('Crypto Freqs #', ''); // Strip out 'Crypto Freqs #' to get only the ID
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <html class="result-page">
    <div className="result-container">
      {/* Logo in the top left, clickable */}
      <div className="logo-container results-logo" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
        <img src="/assets/LOGO.png" alt="Logo" className="logo-image" />
        

        {/* Scaled text */}
        {metaData && metaData.meta && (
          <h2 className="logo-text">{extractId(metaData.meta.name)}</h2>
        )}
      </div>

      {/* Images side by side */}
      <div className="images-container">
        <img
          src={`/images/${id}.png`}
          alt={`Crypto Freq ${id}`}
          className="square-image"
          onClick={handleImageClick}
          style={{ cursor: 'pointer' }}
        />
        <img
          src={`/images/${id}.png`}
          alt={`Crypto Freq ${id}`}
          className="round-image"
          onClick={handleImageClick}
          style={{ cursor: 'pointer', borderRadius: '50%' }}
        />
      </div>
    </div>
    </html>
  );
};

export default ResultPage;
