// modelViewerComponent.js - powered by Procrastinate Pixels - www.procrastinatepixels.com
import React, { useEffect, useRef, useState } from "react";
import "@google/model-viewer";

const ModelViewer = () => {
  const modelViewerRef = useRef(null);
  const [controlsEnabled, setControlsEnabled] = useState(false); // State to manage controls
  const [permissionGranted, setPermissionGranted] = useState(false); // State for device permission
  const [isMobile, setIsMobile] = useState(false); // State to track if the device is mobile
  const [showModal, setShowModal] = useState(false); // State for showing modal

  // Request device motion permission on user interaction (e.g., modal button click)
  const requestPermissionForIOS = async () => {
    if (
      typeof DeviceMotionEvent !== "undefined" &&
      typeof DeviceMotionEvent.requestPermission === "function"
    ) {
      try {
        const permission = await DeviceMotionEvent.requestPermission();
        if (permission === "granted") {
          setPermissionGranted(true); // Mark permission as granted
          window.addEventListener("deviceorientation", handleDeviceOrientation);
        }
      } catch (error) {
        console.error("Device motion permission not granted:", error);
      }
    } else {
      // For non-iOS devices, no need for permission
      setPermissionGranted(true);
      window.addEventListener("deviceorientation", handleDeviceOrientation);
    }

    // Close the modal after permission is granted
    setShowModal(false);
  };

  // Mouse movement handler for desktop
  const handleMouseMove = (event) => {
    const { clientX, clientY } = event;
    const { innerWidth, innerHeight } = window;

    const rotationY = -(clientX / innerWidth - 0.5) * 2 * Math.PI * 0.3;
    const rotationX = -(clientY / innerHeight - 0.5) * 6 * Math.PI * 0.1;

    if (modelViewerRef.current) {
      modelViewerRef.current.cameraOrbit = `${rotationY}rad ${rotationX + Math.PI / 2}rad 2m`;
    }
  };

  // Device orientation handler for mobile
  const handleDeviceOrientation = (event) => {
    const { beta, gamma } = event;
    const rotationX = beta ? (beta / 90) * Math.PI * 0.2 : 0;
    const rotationY = gamma ? (gamma / 45) * Math.PI * 0.2 : 0;

    if (modelViewerRef.current) {
      modelViewerRef.current.cameraOrbit = `${rotationY}rad ${rotationX + Math.PI / 2}rad 2m`;
    }
  };

  useEffect(() => {
    // Check if the device supports touch events (typically indicates a mobile device)
    const isTouchDevice = "ontouchstart" in window || navigator.maxTouchPoints > 0;
    setIsMobile(isTouchDevice);

    if (isTouchDevice) {
      setShowModal(true); // Show the modal if the device is mobile
    }

    // Attach mousemove event listener for desktop
    window.addEventListener("mousemove", handleMouseMove);

    // Enable camera controls after the model is loaded
    modelViewerRef.current?.addEventListener("load", () => {
      setControlsEnabled(true);
    });

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("deviceorientation", handleDeviceOrientation);
    };
  }, []);

  return (
    <div>
      {/* Modal for requesting device motion permission */}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Enable Device Motion</h2>
            <p>For the best experience, please enable motion controls on your device.</p>
            <button onClick={requestPermissionForIOS}>Allow Motion Controls</button>
          </div>
        </div>
      )}

      <model-viewer
        ref={modelViewerRef}
        camera-controls={controlsEnabled}
        disable-pan
        disable-tap
        disable-zoom
        interaction-prompt="none" // Disable the finger swipe animation
        loading="eager"
        alt="Freq"
        src="/models/Freqmouth2.gltf"
        style={{
          width: "100%",
          height: "70vh", // This makes the height 70% of the viewport height
          maxHeight: "700px", // This ensures it doesn't get too tall on large screens
        }}
      ></model-viewer>
    </div>
  );
};

export default ModelViewer;
