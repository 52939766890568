import React, { useState, useCallback, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Autoplay } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import './App.css';

const TOTAL_IMAGES = 1097;
const INITIAL_LOAD = 20;
const BATCH_SIZE = 10;

const Gallery = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [loadedImages, setLoadedImages] = useState(INITIAL_LOAD);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const getImageSrc = useCallback((index) => {
    const id = index + 1;
    return `/jpg/${id}.jpg`;
  }, []);

  const handleImageClick = (id) => {
    navigate(`/result/${id}`);
  };

  const loadMoreImages = useCallback(() => {
    setLoadedImages(prev => Math.min(prev + BATCH_SIZE, TOTAL_IMAGES));
  }, []);

  useEffect(() => {
    // Simulate initial loading
    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    const numberValue = parseInt(value, 10);
    if ((numberValue >= 1 && numberValue <= 1097) || (numberValue >= 3320 && numberValue <= 3333)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const handleSubmit = () => {
    const numberValue = parseInt(searchTerm, 10);
    if ((numberValue >= 1 && numberValue <= 1097) || (numberValue >= 3320 && numberValue <= 3333)) {
      setIsValid(true);
      navigate(`/result/${numberValue}`);
    } else {
      setIsValid(false);
    }
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  if (isLoading) {
    return <div className="loading">Loading gallery...</div>;
  }

  return (
    <div className="gallery-container">
      <div className="logo-container gallery-logo" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
        <img src="/assets/LOGO.png" alt="Logo" className="logo-image" />
      </div>

      <Swiper
        modules={[EffectCoverflow, Autoplay]}
        spaceBetween={30}
        centeredSlides={true}
        effect="coverflow"
        coverflowEffect={{
          rotate: 30,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        }}
        slidesPerView={1} // Default to 1 slide
        breakpoints={{
          431: { // For screens larger than 430px
            slidesPerView: 3,
          }
        }}
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
        loop={false}
        preloadImages={false}
        watchSlidesProgress={true}
        onReachEnd={loadMoreImages}
      >
        {Array.from({ length: loadedImages }, (_, index) => (
          <SwiperSlide key={index}>
            <div
              className="image-container"
              onClick={() => handleImageClick(index + 1)}
              style={{ cursor: 'pointer' }}
            >
              <img 
                src={getImageSrc(index)} 
                alt={`Crypto Freq ${index + 1}`} 
                className="gallery-image" 
                loading="lazy"
                style={{ maxWidth: '100%', height: 'auto' }}
              />
              <p>ID: #{(index + 1).toString().padStart(4, '0')}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="search-holder">
        <div className="search-container">
          <input
            type="text"
            placeholder="Enter ID #"
            value={searchTerm}
            maxLength={4}
            onChange={handleSearchChange}
            className={`gallery-search-input ${!isValid ? 'invalid' : ''}`}
          />
          <div className="search-button" onClick={handleSubmit}></div>
        </div>
        
      </div>
    </div>
  );
};

export default Gallery;
//test